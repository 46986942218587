import { TransformTag } from '@/utils'

export default function () {
  const get = path => this.$path(path, this.$store.state)

  return [
    {
      component: 'Section',
      props: {
        width: 'medium'
      },
      blocks: {
        default: [
          {
            component: 'Text',
            props: {
              style: 'padding-block: 0 3rem; border-bottom: 1px solid',
            },
            data: {
              content: TransformTag(get('Page.item.customFields.contact-content')),
            },
          },
          {
            component: 'Address',
            props: {
              style: 'padding-top: 3rem',
              name: get('Page.item.name'),
              address: get('Page.item.address.formatted'),
              email: get('Page.item.customFields.company-info.email'),
              phone: get('Page.item.customFields.company-info.phone'),
            },
          }
        ]
      }
    },

    {
      condition: get('Root.item.address'),
      component: 'Section',
      props: {
        width: 'full',
        style: '--section-padding-block: 0rem',
      },
      blocks: {
        default: [
          {
            component: 'Map',
            props: {
              displayAddress: false
            },
            data: {
              ...get('Root.item.address'),
            },
          },
        ],
      },
    },
  ]
}

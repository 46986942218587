<template>
	<div class="kpb-address">
		<h4>{{ name }}</h4>
		<address>{{ address }}</address>
		<div class="kpb-address__contact">
			<a :href="`tel:${phone}`">{{ phone }}</a>
			<a :href="`mailto:${email}`">{{ email }}</a>
		</div>
	</div>
</template>

<script>
/** 
 * Address block
 */
export default {
	props: {
		name: String,
		address: String,
		phone: String,
		email: String,
	}
}
</script>

<style lang="scss">
.kpb-address {
	h4 {
		margin: 0;
	}

	address {
		font-style: normal;
	}

	&__contact {
		margin-top: 1rem;

		a {
			display: block;
		}
	}
}
</style>
<template>
  <Loader :value="promises.fetch" class="contact-page">
    <Blocks :blocks="config" :customComponents="customComponents" />
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'

// pagebuilder
import config from '@/config/contact.js'
import { Blocks } from '@kvass/pagebuilder'

// custom components
import Address from '@/components/Address'
import Html from '@/components/Html'
import Portal from '@/components/Portal'

export default {
  computed: {
    ...mapState('Page', ['promises', 'item']),
    config() {
      return config.call(this)
    },
    customComponents() {
      return { Portal, Html, Address }
    },
  },
  methods: {
    ...mapActions('Page', ['fetch']),
  },
  created() {
    this.fetch()
  },
  metaInfo() {
    return {
      title: this.$t('getInTouch'),
    }
  },
  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.contact-page {
  h1 {
    margin-top: 0;
  }

  .contact-lead-section {
    @include respond-below('tablet') {
      --section-padding-inline: 0rem;
    }

    .contact-lead {
      padding: 0;

      @include respond-above('tablet') {
        gap: 3rem;
        padding: 2rem 0;
      }

      @include respond-below('tablet') {
        flex-wrap: wrap !important;
      }

      &__image {
        img {
          background-color: inherit;
          object-fit: contain;
        }
      }
    }
  }
}
</style>
